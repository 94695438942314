import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Components
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

// Context
import { useColorContext } from "../components/context/color-context";

const Page = styled.div`
  margin: 40px 0 0 0;

  @media (max-width: 900px) {
    margin: 55px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  & .full-width-text {
    & .text-container {
      grid-column: 1 / 7;

      & p {
        font-size: 40px;
        line-height: 52px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }
  }

  & .text {
    & .text-container {
      grid-column: 2 / 6;

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-text-container {
        max-width: 895px;
        margin: 0 auto;

        & .section-title {
          & h1 {
            font-size: 40px;
            line-height: 52px;

            @media (max-width: 768px) {
              font-size: 26px;
              line-height: 34px;
            }
          }
        }

        & .section-text {
          & ol {
            margin: 1em 0;

            & li {
              list-style: decimal;
              margin: 0 0 1em 20px;
              padding: 0 0 0 10px;

              &:last-of-type {
                margin: 0 0 0 20px;
              }
            }
          }
        }
      }
    }
  }

  & .gallery {
    grid-row-gap: 80px;

    @media (max-width: 768px) {
      grid-row-gap: 40px;
    }

    & .single-image-container {
      grid-column: span 2;

      @media (max-width: 1200px) {
        grid-column: span 3;
      }

      @media (max-width: 768px) {
        grid-column: 1 / 7;
      }

      & .inner-grid {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        height: 100%;
      }

      & .name-subtitle-container {
        min-height: 60px;
        // min-height: 90px;

        @media (max-width: 768px) {
          min-height: unset;
        }
      }

      & .subtitle {
        & p {
          margin: 0;
        }
      }

      & .text-image-container {
        flex: 1;

        & .image-container {
          position: relative;
          margin: 1em 0;
        }

        & .text {
          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        & img {
          margin: 0 auto;

          object-fit: contain;

          top: 50%;
          transform: translateY(-50%);
          position: relative;

          @media (max-width: 768px) {
            top: unset;
            transform: unset;
          }

          &.landscape {
            width: 100%;
            height: 100%;
            max-height: calc(100% - 30px - 30px);

            @media (max-width: 768px) {
              max-height: 100%;
            }
          }

          &.portrait {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  & .support {
    & .section-title,
    & .support-logos {
      grid-column: 1 / 7;
    }

    & .support-logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & .single-image-container {
        margin: 0 40px 0 0;

        &:last-of-type {
          margin: 0;
        }

        & img {
          width: auto;
          height: 30px;
        }

        @media (max-width: 768px) {
          margin: 0 15px 0 0;

          & img {
            height: 20px;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;

  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const About = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);

  useEffect(() => {
    setPageType(`about`);
  }, []);

  const content = data.prismicAbout.data.body.map((content, index) => {
    if (content.slice_type === "full_width_text") {
      return (
        <Grid
          key={`full_width_text_${index}_${content.id}`}
          className="full-width-text"
        >
          <div
            className="text-container"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Grid>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Grid key={`text_${index}_${content.id}`} className="text">
          <div className="text-container">
            <div className="inner-text-container">
              <div
                className="section-title"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_title.html,
                }}
              />
              <div
                className="section-text"
                dangerouslySetInnerHTML={{
                  __html: content.primary.section_text.html,
                }}
              />
            </div>
          </div>
        </Grid>
      );
    }

    if (content.slice_type === "images") {
      const images = content.items.map((item, innerIndex) => (
        <div
          key={`single_image_item_${innerIndex}_${content.id}`}
          className="single-image-container"
        >
          <div className="inner-grid">
            <div className="name-subtitle-container">
              <div
                className="name uppercase"
                dangerouslySetInnerHTML={{
                  __html: item.name.html,
                }}
              />
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: item.subtitle.html,
                }}
              />
            </div>

            <div className="text-image-container">
              <div className="image-container">
                <AspectRatioImageContainer image={null} padding={`61`}>
                  {item.image.fluid !== null &&
                    item.image.fluid !== undefined && (
                      <img
                        className={`${ImageOrientation(item.image)}`}
                        srcSet={item.image.fluid.srcSetWebp}
                        src={item.image.fluid.srcWebp}
                        alt={item.image.alt}
                        loading={`lazy`}
                      />
                    )}
                </AspectRatioImageContainer>
              </div>

              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: item.text.html,
                }}
              />
            </div>
          </div>
        </div>
      ));

      return (
        <Grid key={`images_${index}_${content.id}`} className="gallery">
          {images}
        </Grid>
      );
    }

    if (content.slice_type === "support") {
      const images = content.items.map((item, innerIndex) => (
        <div
          key={`single_image_item_${innerIndex}_${content.id}`}
          className="single-image-container"
        >
          <div className="image-container">
            {item.support_image.fluid !== null &&
              item.support_image.fluid !== undefined && (
                <img
                  // className={`${ImageOrientation(item.support_image)}`}
                  srcSet={item.support_image.fluid.srcSetWebp}
                  src={item.support_image.fluid.srcWebp}
                  alt={item.support_image.alt}
                  loading={`lazy`}
                />
              )}
          </div>
        </div>
      ));

      return (
        <Grid key={`images_${index}_${content.id}`} className="support">
          <div className="section-title">
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: content.primary.support_text.html,
              }}
            />
          </div>

          <div className="support-logos">{images}</div>
        </Grid>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicAbout.data.title.text} | Three Rivers | Bexley`}
        meta={[
          {
            name: "title",
            content: `${data.prismicAbout.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "og:title",
            content: `${data.prismicAbout.data.title.text} | Three Rivers | Bexley`,
          },
          {
            property: "twitter:title",
            content: `${data.prismicAbout.data.title.text} | Three Rivers | Bexley`,
          },
        ]}
      />
      <Page>
        <ContentContainer>{content}</ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(About);

export const query = graphql`
  query {
    prismicAbout {
      _previewable
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicAboutBodyFullWidthText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicAboutBodyImages {
            id
            slice_type
            items {
              name {
                html
              }
              subtitle {
                html
              }
              text {
                html
              }
              image {
                alt
                dimensions {
                  width
                  height
                }
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
          ... on PrismicAboutBodyText {
            id
            slice_type
            primary {
              section_title {
                html
              }
              section_text {
                html
              }
            }
          }
          ... on PrismicAboutBodySupport {
            id
            slice_type
            primary {
              support_text {
                html
              }
            }
            items {
              support_image {
                fluid {
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
